@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/utilities';

$form-background: #f7f8fa;

.form {
	background-color: $form-background;
	margin: 0;
	border-radius: 5px;

	fieldset {
		border: none;
		padding: 0;
		margin: 0;
		width: 100%;
		padding: 3px;

		legend {
			@include visually-hidden;
		}

		label {
			cursor: pointer;
			display: inline-block;

			width: 50%;

			input {
				@include visually-hidden;
			}

			input:checked + span {
				background-color: white;
				font-weight: 700;
				opacity: 1;
				border: 0.5px solid rgba(0, 0, 0, 0.04);
				box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
				border-radius: 5px;
			}

			span {
				font-size: 16px;
				font-weight: 400;
				height: 40px;
				line-height: 40px;
				width: 100%;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				@include sm-down {
					line-height: 16px;
				}
			}
		}
	}
}
