.spinner_container {
	display: flex;
	justify-content: center;
	width: 100%;
}

.spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border-top-color: grey;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
